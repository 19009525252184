import { useEffect, useState } from 'react';
import { useSessionStorage } from 'react-use';
import HighlightedBar from '@components/common/highlighted-bar';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

const HighlightedBarContainer: React.FC<any> = ({ data }) => {
  const html = documentToHtmlString(data);
  const [isClient, setIsClient] = useState<boolean>(false);
  const [highlightedBar, setHighlightedBar] = useSessionStorage(
    'razor-highlightedBar',
    'false',
  );

  useEffect(() => {
    setIsClient(true);
  }, []);

  if (isClient && highlightedBar !== 'true') {
    return (
      <HighlightedBar
        onClose={() => setHighlightedBar('true')}
        variant="highlightedTwo"
        className="text-white"
      >
        <div
          className="flex items-center"
          dangerouslySetInnerHTML={{
            __html: html,
          }}
        />
        {/* <Countdown date={Date.now() + 4000000 * 71} /> */}
      </HighlightedBar>
    );
  }

  return null;
};

export default HighlightedBarContainer;
